import PotacoLogo from './Resources/PotacoStudioLogoBold.png';
import Bluma from './Resources/bluma.png';
import Keety from './Resources/keety.png';
import FamilyPhoto from './Resources/familyphoto.png';
import { SocialIcon } from 'react-social-icons'
import './App.scss';

function App() {
  const onClickBluma = () => {
    window.open("https://store.steampowered.com/app/3032520/BLUMA/", "_blank")
  }

  const onClickKeety = () => {
    window.open("https://apps.apple.com/us/app/keety/id1131054781", "_blank")
  }

  return (
    <div className="app">
      <div className='header'>
        <img className='potaco-logo' src={PotacoLogo} alt='Potaco Studio' />
        <p className='title'>POTACO STUDIO</p>
        <div className='icons'>
          <SocialIcon url="https://discord.gg/HYagDfy2Zz" target="_blank" rel="noopener noreferrer" />
          <SocialIcon url="https://bsky.app/profile/potacostudio.bsky.social" target="_blank" rel="noopener noreferrer" />
          <SocialIcon url="mailto:potacostudio@gmail.com" />
        </div>
      </div>
      <div className='about'>
        <div className='text'>
          <p>Potaco Studio is a small independent game studio located in Virginia, USA.</p>
          <p>We are a wife and wife team who enjoy making cute and cozy games.</p>
        </div>
        <img className='family-photo' src={FamilyPhoto} alt='Family' />
      </div>
      <div className='games'>
        <div className='row'>
          <div className='bluma game-img' onClick={onClickBluma}>
            <img src={Bluma} alt='Bluma' />
          </div>
          <div className='description'>
            <p><b>BLUMA</b> is a cozy puzzle-platforming adventure where you make the platforms.</p>
            <p>Coming to <a href='https://store.steampowered.com/app/3032520/BLUMA/'>Steam</a> in 2025.</p>
            <a href='https://dorian-tortellini-34c.notion.site/BLUMA-Press-Kit-18a932906115802c8d3bc51bf13815c0'>BLUMA Press Kit</a>
          </div>
        </div>
        <div className='row'>
          <div className='keety game-img' onClick={onClickKeety}>
            <img src={Keety} alt="Keety" />
          </div>
          <div className='description'>
            <p><b>Keety</b> is an arcade action-shooter based on cat memes.</p>
            <p>Available now on the <a href='https://apps.apple.com/us/app/keety/id1131054781'>iOS App Store.</a></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
